'use strict';

function gallery_next(element) {
    element.preventDefault();

    var gallery_container = $(element.target).closest('.gallery-container');
    var next_image = gallery_container.find('.next');
    var previous_image = gallery_container.find('.previous');
    var active_image = gallery_container.find('.active');
    var current_image_counter = gallery_container.find('.gallery-current-image-no');
    var totalImages = parseInt(gallery_container.find('.gallery-total-image-no').html());

    if(totalImages > parseInt(current_image_counter.html())) {
        next_image.addClass('active');
        previous_image.removeClass('previous');
        active_image.addClass('previous');

        next_image.next().addClass('next');
        next_image.removeClass('next');
        active_image.removeClass('active');

        current_image_counter.html((parseInt(current_image_counter.html()) + 1));
    }
}

function gallery_previous(element) {
    element.preventDefault();

    var gallery_container = $(element.target).closest('.gallery-container');
    var next_image = gallery_container.find('.next');
    var previous_image = gallery_container.find('.previous');
    var active_image = gallery_container.find('.active');
    var current_image_counter = gallery_container.find('.gallery-current-image-no');

    if(parseInt(current_image_counter.html()) > 1) {
        previous_image.addClass('active');
        next_image.removeClass('next');
        active_image.addClass('next');

        previous_image.prev().addClass('previous');
        previous_image.removeClass('previous');
        active_image.removeClass('active');

        current_image_counter.html((parseInt(current_image_counter.html()) - 1));
    }
}

if(document.querySelector('.gallery-next') !== null) {
    // Toggle the sidebar on click
    var galleryNextButtons = document.querySelectorAll('.gallery-next');
    for(var i = 0; i < galleryNextButtons.length; i++) {
        galleryNextButtons[i].addEventListener('click', function(e) {
            gallery_next(e);
        });
    }
}

if(document.querySelector('.gallery-previous') !== null) {
    // Toggle the sidebar on click
    var galleryPreviousButtons = document.querySelectorAll('.gallery-previous');
    for(var i = 0; i < galleryPreviousButtons.length; i++) {
        galleryPreviousButtons[i].addEventListener('click', function(e) {
            gallery_previous(e);
        });
    }
}
