'use strict';

function collapsible(element) {
    element.preventDefault();
    var section = $(element.target).closest('.section-relation');
    var collapsible_content = section.find('.content-collapsible');

    if(collapsible_content.hasClass('expanded')) {
        collapsible_content.addClass('collapsed');
        collapsible_content.removeClass('expanded');
        $(element.target).addClass('collapsed');
        $(element.target).removeClass('expanded');
    } else {
        collapsible_content.addClass('expanded');
        collapsible_content.removeClass('collapsed');
        $(element.target).removeClass('collapsed');
        $(element.target).addClass('expanded');
    }
}

if(document.querySelector('.collapse-content') !== null) {
    // Toggle the sidebar on click
    var collapsibles = document.querySelectorAll('.collapse-content');
    for(var i = 0; i < collapsibles.length; i++) {
        collapsibles[i].addEventListener('click', function(e) {
            collapsible(e);
        });
    }
}
